<style >
* {
  word-break: break-word !important;
}
.bx-0 {
  border-right: 0 !important;
  border-left: 0 !important;
}

.w-100 {
  width: 100%;
  min-width: 100%;
}
.h-100 {
  height: 100%;
  min-height: 100%;
}
.h-100px {
  min-height: 100px !important;
  height: 100px !important;
}
.w-100px {
  min-width: 100px !important;
  width: 100px !important;
}
.glass {
  background-color: #ffffff45;
}
.glass2 {
  background-color: #ffffff90;
}
.capitalize {
  text-transform: capitalize;
}
.bordered-title {
  color: black;
  font-size: 32px;
  background: #fbfbfb66;
  border: 1px solid;
  border-right: 0;
  border-left: 0;
  padding: 16px 32px;
  text-transform: uppercase;
}
.panaBg {
  top: 0;
  height: 100%;
  width: 5398px;
  left: 0;
  position: fixed;
  background-image: url("~@/assets/bgs/pana.jpg");
  background-position-x: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: panaAnimation 300s ease infinite;
}
@keyframes panaAnimation {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0px);
  }
}
.panaBg .gradOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  background: linear-gradient(45deg, #f5f5f5, #1e1e1e8a);
}

.v-main__wrap {
  margin: auto;
  max-width: 80% !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

@media only screen and (max-width: 960px) {
  .v-main__wrap {
    max-width: 100% !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
}
.footerContactus {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.footerContactus a {
  color: white !important;
}
</style>


<template>
  <v-app>

    <app-nav></app-nav>
    <app-drawer></app-drawer>
    <div class="panaBg">
      <div class="gradOverlay">s</div>
    </div>
    <v-main>
      <!--    <div
        style="position: fixed;
    height: 100%;
 
    width: 100%;"
      ></div> -->
      <router-view></router-view>
      <!--    <HelloWorld v-if="false" /> -->
    </v-main>
    <v-footer v-if="$vuetify.breakpoint.mdAndUp" dark padless dense>
      <v-col cols="12" md="4" class="pa-0 px-2 footerContactus">
        {{ $t("contactUs").toUpperCase() }}
        <div class="nums mx-2">
          <v-btn tile class="pa-1 flex-grow-1" text>
            <a href="tel:+46733060010" style="text-decoration:none!important">
              +46733060010
            </a>
          </v-btn>
        </div>
      </v-col>
      <v-col class="text-center mx-auto pa-0 px-2" cols="12" md="4">
        2005 — <strong>SUDTEK</strong>
      </v-col>
      <v-col cols="12" md="4" class="ml-auto text-center pa-0 px-4">
        <v-btn tile class="pa-4 flex-grow-1 ml-auto" width="150" text>
          <a
            href="mailto:info@sudtek.se"
            style="text-decoration:none!important;color:white"
            ><v-icon>mdi-mail</v-icon> info@sudtek.se
          </a>
        </v-btn>
      </v-col>
    </v-footer>
    <v-footer v-else dark padless dense>
      <v-col cols="12" md="4" class="ml-auto text-center pa-0 px-4">
        <v-btn tile class="pa-4 flex-grow-1 ml-auto" width="150" text>
          <a
            href="mailto:info@sudtek.se"
            style="text-decoration:none!important;color:white"
            ><v-icon>mdi-mail</v-icon> info@sudtek.se
          </a>
        </v-btn>
      </v-col>
      <v-col cols="12" md="4" class="pa-0 px-2 footerContactus">
        <div class="nums mx-auto">
          <!--         <v-btn tile class="pa-1 flex-grow-1" text>
            <a href="tel:+46737551263" style="text-decoration:none!important"
              >+46737551263
            </a>
          </v-btn> -->

          <v-btn tile class="pa-1 flex-grow-1" text>
            <a href="tel:+46733060010" style="text-decoration:none!important">
              +46733060010
            </a>
          </v-btn>
        </div>
      </v-col>
      <v-col class="text-center mx-auto pa-0 px-2" cols="12" md="4">
      © 2020 Copyright <strong> www.sudtek.se</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import AppNav from "./components/AppNav";
import AppDrawer from "./components/AppDrawer";
import i18n from "@/plugins/i18n";

export default {
  name: "App",

  components: {
    AppNav,
    AppDrawer
  },

  computed: {
    lang: {
      get() {
        return i18n.locale;
      },
      set(lang) {
        i18n.locale = lang;
      }
    }
  }
};
</script>
