


<style>
.mainCard {
  border-radius: 16px !important;
  background-color: #ffffff45 !important;
  padding: 1rem;
}
/* .mainCard::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff45;
  border-radius: 16px !important;
} */
.mainCard svg#logo {
  position: relative;
}
.mainCard svg#logo g#letters path {
  fill-opacity: 0;
  stroke: black;
  stroke-width: 0.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 700;
  stroke-dashoffset: 700;
  animation: dash 6s linear forwards;
}

.mainCard svg#logo g#leaf path {
  fill-opacity: 0;
  stroke: green;
  stroke-width: 0.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 700;
  stroke-dashoffset: 700;
  animation: dash 4s linear forwards 1s;
}
.mainCard .slogan {
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 8px;
}
@media only screen and (max-width: 960px) {
  .mainCard .slogan {
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 4px;
  }

  .largeBtn .v-btn__content {
    justify-content: space-between;
  }
}
.largeBtn {
  min-height: 100px;
}
.largeBtn .v-btn__content {
  display: flex;
}
.largeBtn .v-btn__content .v-icon {
  font-size: 5rem;
}
.largeBtn .v-btn__content span {
  font-size: 1.5rem;
}

.aboutUsCard .v-card__title {
  font-size: 1.5rem;
  text-transform: uppercase;
}
.aboutUsCard .v-card__text {
  font-size: 22px;
  padding: 1rem;
  color: #00000075;
  line-height: 150%;
  font-weight: 300;
}
@media only screen and (max-width: 960px) {
  .aboutUsCard .v-card__text {
    font-size: 18px;
  }
}

.aboutUsCard .v-card__text .highlight {
  font-weight: 400;
  transition: all 0.6s ease 0.6s !important;
}

.aboutUsCard .v-card__text.aos-animate .highlight {
  color: black;
}

.aboutUsCard .serviceBtnsHolder {
  display: flex;
}
.aboutUsCard .serviceBtnsHolder .serviceBtn {
  flex: 1;
  height: 150px;
}
.aboutUsCard .serviceBtnsHolder .serviceBtn .v-btn__content {
  flex-direction: column;
}
.aboutUsCard .serviceBtnsHolder .serviceBtn span {
  font-size: 20px;
  padding: 1rem;
  color: black;
}
.aboutUsCard .serviceBtnsHolder .serviceBtn svg {
  height: 100px;
  width: 100px;
  filter: grayscale(1);
}
.aboutUsCard .serviceBtnsHolder .serviceBtn:hover svg {
  filter: grayscale(0);
}

/* 


.logoCard.aos-animate svg g#letters path {
  stroke-dashoffset: 0;
}
 */
@keyframes dash {
  75% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
    fill-opacity: 1;
  }
}

.slidingDown {
  animation: slidingDown 4s ease infinite;
  opacity: 0;
}
@keyframes slidingDown {
  0% {
    transform: translateY(0%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }

  100% {
    transform: translateY(20px);
    opacity: 0;
  }
}
.slideRight {
  animation: slideRight 4s ease infinite;
}
@keyframes slideRight {
  0% {
    transform: translateX(0%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }

  90% {
    opacity: 0;
  }

  100% {
    transform: translateX(50px);
    opacity: 0;
  }
}
.page {
  position: relative;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0%;
  min-width: 100%;
  padding: 0%;
}
.w-100 {
  width: 100%;
  min-width: 100%;
}
.h-100 {
  height: 100%;
  min-height: 100%;
}
.h-100px {
  height: 100px;
}

/* 
.drawIt {
  fill-opacity: 0;
  stroke: black;
  stroke-dasharray: 10000;
  stroke-dashoffset: 10000;
  animation: dash all 4s ease 1s, fill-opacity 3s ease 1.5s;
}
.aos-animate .drawIt {
  fill-opacity: 1;
  stroke-dashoffset: 0;
} */
</style>
<template>
  <div class="home">
    <v-container
      id="splash"
      class="page"
      style="background: linear-gradient(0deg, #f5f5f5,#f5f5f58c);"
    >
      <v-img
        v-if="$vuetify.breakpoint.mdAndUp"
        style="position: absolute;top: 0px;height: 100%;width: 100%;left: 0;object-fit: cover;filter: blur(12px) grayscale(.1);"
        :src="require('@/assets/bgs/cleaning-bg-1.jpg')"
      ></v-img>
      <v-col class="ma-auto" cols="11" md="8" lg="5">
        <v-card class="mainCard" color="reds" data-aos="fade-in" flat>
          <inline-svg
            id="logo"
            :src="require('@/assets/logos/sudtek_logo_dark.svg')"
          />
          <v-divider color="grey" class="my-1"></v-divider>
          <v-card-title
            data-aos="zoom-in"
            data-aos-delay="1200"
            class="slogan justify-center"
            >Städtjänster</v-card-title
          >
          <!--      <v-row no-gutters>
            <v-col
              class="pa-0"
              cols="4"
              data-aos="fade-right"
              data-aos-delay="1500"
            >
              <v-btn href="tel:+46737551263" text class="w-100 h-100">
                <v-icon x-large>mdi-phone</v-icon>
                <a href="tel:+46737551263"> </a>
              </v-btn>
            </v-col>
            <v-col
              class="pa-0"
              cols="4"
              data-aos="zoom-in"
              data-aos-delay="1500"
            >
              <v-btn href="mailto:info@sudtek.se" text class="w-100 h-100">
                <v-icon x-large>mdi-mail</v-icon>
                <div>custo@sudtel.net</div>
              </v-btn>
            </v-col>
            <v-col
              class="pa-0"
              cols="4"
              data-aos="fade-left"
              data-aos-delay="1500"
            >
              <v-btn text class="w-100 h-100">
                <v-icon x-large>mdi-map</v-icon>
                <div>Find us</div>
              </v-btn>
            </v-col>
          </v-row> -->
          <v-divider color="grey" class="my-2"></v-divider>
          <v-row no-gutters class="h-100px">
            <v-col
              class="pa-0"
              cols="12"
              md="6"
              data-aos="fade-right"
              data-aos-delay="1500"
            >
              <v-btn to="#aboutUs" tile text class="w-100 h-100 largeBtn">
                <span
                  v-bind:class="
                    $vuetify.breakpoint.mdAndUp ? 'white--text' : 'black--text'
                  "
                  >{{ $t("aboutUs") }}</span
                >
                <v-icon
                  v-if="this.$vuetify.breakpoint.mdAndUp"
                  class="slidingDown"
                  >mdi-chevron-down</v-icon
                >
                <v-icon v-else class="slideRight">mdi-chevron-right</v-icon>
                <!--  <a href="tel:+46737551263">
              </a> -->
              </v-btn>
            </v-col>
            <v-col
              class="pa-0"
              cols="12"
              md="6"
              data-aos="zoom-in"
              data-aos-delay="1500"
            >
              <v-btn
                :to="{ path: 'services' }"
                tile
                text
                class="w-100 h-100 largeBtn"
              >
                <span
                  v-bind:class="
                    $vuetify.breakpoint.mdAndUp ? 'white--text' : 'black--text'
                  "
                  >{{ $t("ourServices") }}</span
                >
                <!--    <div>custo@sudtel.net</div> -->
              </v-btn>
            </v-col>
          </v-row>
        </v-card>

      </v-col>
    </v-container>
    <v-container id="aboutUs" class="page align-start white">
      <v-card tile flat class="aboutUsCard">
        <v-img
          :src="require('@/assets/bgs/cleaning-bg-2.jpg')"
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.99)"
          class="white--text align-end"
          height="350px"
        >
          <div class="d-flex overlay glass2">
            <v-card-title class="ma-auto bordered-title">
              <span>{{ $t("aboutUs") }}</span>
            </v-card-title>
          </div>

        </v-img>
        <v-card-text
          data-aos="fade-right"
          data-aos-duration="600"
        >
            <span v-html="$t('aboutUsContent') "></span>

       
        </v-card-text>
        <v-flex text-center>
          <v-btn
            :to="{ path: 'services' }"
            color="blue"
            class="largeBtn bx-0"
            text
            outlined
          >
            <span>{{ $t("ourServices") }}</span>
            <v-icon class="slideRight ">mdi-chevron-right</v-icon>
          </v-btn>
        </v-flex>
        <!--   <v-card tile flat class="pa-0">
          <v-card-title class="justify-center">
            <span
              data-aos="fade-right"
              data-aos-duration="600"
              :data-aos-delay="300"
            >
              {{ $t("ourServices") }}
            </span>
            <v-divider
              data-aos="fade-left"
              data-aos-duration="600"
              :data-aos-delay="300"
            ></v-divider>
          </v-card-title>
          <div class="serviceBtnsHolder">
            <v-btn
              color="transparent"
              data-aos="zoom-in"
              data-aos-duration="600"
              :data-aos-delay="1200 + x * 300"
              v-for="(s, x) in this.services"
              v-bind:key="x"
              class="serviceBtn"
              text
            >
              <inline-svg :src="require('@/assets/icons/' + s.icon + '.svg')" />
              <span>
                {{ $t(s.title) }}
              </span>
            </v-btn>
          </div>
        </v-card> -->
      </v-card>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  data: () => ({
    services: [
      {
        title: "movingCleaning",
        icon: "moving_cleaning"
      },
      {
        title: "homeCleaning",
        learnMore: false,
        icon: "house_cleaning"
      },
      {
        title: "officeCleaning",
        icon: "office"
      }
    ]
  }),
  components: {}
};
</script>
