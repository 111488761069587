
<template>
  <v-app-bar app  dark>
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.smAndDown"
      @click="saveObj({ key: 'inDrawer', val: true })"
    ></v-app-bar-nav-icon>
    <div class="d-flex align-center">
      <v-img
        alt="Vuetify Logo"
        class="shrink mr-2"
        contain
        :src="require('@/assets/logos/sudtek-logo.png')"
        transition="scale-transition"
        width="100"
      />
    </div>

    <v-spacer></v-spacer>
    <div
      class="ml-auto my-auto d-flex align-center"
      v-if="$vuetify.breakpoint.mdAndUp"
    >
      <v-btn :to="{ path: '/', hash: 'splash' }" small text>{{
        $t("home")
      }}</v-btn>
      <v-btn class="px-2" :to="{ path: '/', hash: 'aboutUs' }" small dark text>
        <span>{{ $t("aboutUs") }}</span>
      </v-btn>
      <v-btn
        class="px-2"
        :to="{ path: 'services', name: 'Services' }"
        small
        dark
        text
      >
        <span>{{ $t("ourServices") }}</span>
      </v-btn>

      <v-btn
        class="px-2"
        :to="{ path: 'environmental_policy', name: 'EnvironmentalPolicy' }"
        small
        dark
        text
      >
        <span>{{ $t("EnvironmentalPolicy") }}</span>
      </v-btn>
      <v-btn
        class="px-2"
        :to="{ path: 'contact_us', name: 'ContactUs' }"
        small
        dark
        text
      >
        <span>{{ $t("contactUs") }}</span>
      </v-btn>
    </div>
    <v-btn-toggle
      borderless
      v-model="lang"
      mandatory
      background-color="transparent"
    >
      <!--   <v-btn>
            <v-icon>mdi-format-align-left</v-icon>
          </v-btn>
          -->
      <v-btn color="transparent" value="en">
        <gb-flag code="gb" size="small" class="mx-2" />
      </v-btn>
      <v-btn color="transparent" value="sv">
        <gb-flag code="se" size="small" class="mx-2" />
      </v-btn>
    </v-btn-toggle>
  </v-app-bar>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapMutations } from "vuex";

export default {
  name: "AppNav",
  methods: {
    ...mapMutations(["saveObj"])
  },
  computed: {
    lang: {
      get() {
        return i18n.locale;
      },
      set(lang) {
        i18n.locale = lang;
      }
    }
  }
};
</script>
