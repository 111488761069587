import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "@/plugins/i18n";
import AOS from 'aos'
import 'aos/dist/aos.css'
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  created() {
    AOS.init();
  },
  vuetify,
  render: h => h(App)
}).$mount("#app");
