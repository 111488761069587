import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    inDrawer: false
  },
  mutations: {
    saveObj: (state, opt) => {
      state[opt.key] = opt.val;
    }
  },
  actions: {},
  modules: {}
});