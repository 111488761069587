<template>
  <v-navigation-drawer dark v-model="drawer" left app temporary>
    <v-card class="text-center mt-5" color="transparent" elevation="0" to="/">
      <v-img
        contain
        height="50px"
        :src="require('@/assets/logos/sudtek-logo.png')"
        max-width="75px"
        class="ma-auto"
        style="object-fit: contain"
      ></v-img>
      <v-divider></v-divider>
    </v-card>
    <v-list-item dense to="/">
      <v-list-item-title>{{ $t("home").toUpperCase() }}</v-list-item-title>
    </v-list-item>
    <!--<v-expansion-panels accordion text>
        <v-expansion-panel class="transparent">
          <v-expansion-panel-header>
            {{ $t("recyclingProducts") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list class="pa-0">
              <v-list-item dense :to="{ path: 'cleaning#house_cleaning' }">
                <v-list-item-title>Product 1</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>-->

    <v-list-item dense :to="{ path: '/', hash: 'aboutUs' }">
      <v-list-item-title>
        {{ $t("aboutUs").toUpperCase() }}
      </v-list-item-title>
    </v-list-item>
    <v-list-item dense :to="{ path: 'services', name: 'Services' }">
      <v-list-item-title>
        {{ $t("ourServices").toUpperCase() }}</v-list-item-title
      >
    </v-list-item>
    <v-list-item dense :to="{ path: 'environmental_policy', name: 'EnvironmentalPolicy' }">
      <v-list-item-title>
        {{ $t("EnvironmentalPolicy").toUpperCase() }}</v-list-item-title
      >
    </v-list-item>

    
    <v-list-item dense :to="{ path: 'contact_us', name: 'ContactUs' }">
      <v-list-item-title>
        {{ $t("contactUs").toUpperCase() }}
      </v-list-item-title>
    </v-list-item>
  </v-navigation-drawer>
</template>
<script>
import i18n from "@/plugins/i18n";
import { mapMutations, mapState } from "vuex";

export default {
  name: "AppNav",

  methods: {
    ...mapMutations(["saveObj"]),
  },
  computed: {
    ...mapState(["inDrawer"]),

    drawer: {
      get() {
        return this.inDrawer;
      },
      set(value) {
        this.saveObj({ key: "inDrawer", val: value });
      },
    },
    lang: {
      get() {
        return i18n.locale;
      },
      set(lang) {
        i18n.locale = lang;
      },
    },
  },
};
</script>