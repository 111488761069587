import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import goTo from 'vuetify/es5/services/goto';
import lineClamp from 'vue-line-clamp'
import VueFlags from "@growthbunker/vueflags";
Vue.use(VueFlags, {
  iconPath: "img/flags/"
});

Vue.use(lineClamp);
Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/services",
    name: "Services",

    component: () =>
      import("../views/Services.vue")
  },
  {
    path: "environmental_policy",
    name: "EnvironmentalPolicy",
    component: () => import("../views/EnvironmentalPolicy.vue")
  },
  {
    path: "/contact_us",
    name: "ContactUs",
    component: () =>
      import("../views/ContactUs.vue")
  }
];

/* const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
 */
const router = new VueRouter({
  mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;
    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }
    return goTo(scrollTo, {
      offset: 64
    });
  },
  base: process.env.BASE_URL,
  routes
});
export default router;