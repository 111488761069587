import Vue from "vue";
import Vuetify from "vuetify/lib";
import {
    InlineSvgPlugin
} from 'vue-inline-svg';
Vue.use(InlineSvgPlugin);
import ReadMore from 'vue-read-more';

Vue.use(ReadMore);
Vue.use(Vuetify);

export default new Vuetify({});