import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    home: 'Home',
    book: "book now",
    weConfirmBooking: "we will contact you to confirm your booking",
    sendUsAMessage: 'send us a message',
    thankYouForContacting: 'Thank you for contacting us',
    contactInfo: 'Contact information',
    serviceType: 'service type',
    spaceMeters: 'Space in M²',
    fullName: 'full name',
    email: 'email Address',
    address: 'address',
    phoneNumber: 'phone number',
    aboutUsContent: 'Sudtek is a cleaning company based in Linköping but takes on pre-booked cleaning assignments in almost all of Sweden.  Sudtek offers <span class="highlight"> high-quality</span> services in home cleaning, moving cleaning, company cleaning, construction cleaning and much more in the area.  We also perform work such as window cleaning, floor care and what is required to make your home or workplace shiny clean and fresh.  Our super friendly staff has extensive experience and knowledge in everything that has to do with cleaning.  We strive to maintain a high quality of the services we provide.  Our staff is service-oriented and <span class="highlight"> quality-conscious</span> with a focus on <span class="highlight"> 100% customer satisfaction.</span>  Should a mistake occur, we will fix it immediately!  Our customer base is based on references from existing customers.  Sudtek prioritizes security for our customers and employees.  We have comprehensive liability insurance that covers any damage in connection with the cleaning.',
    send: 'send',
    cancel: 'cancel',
    aboutUs: "about us",
    thankYou: "thank you",
    contactUs: "contact us",
    cleaningServices: "Cleaning Services",
    ourServices: "Our Services",
    movingCleaning: "Moving Cleaning",
    readMore: "Read more",
    showLess: "Show less",
    movingCleaningContent: ' Is it time to move?<br/>' +
      'Do you think it is both energy - and time consuming to have to go back and start cleaning the stove and fan, cleaning windows and scrubbing bathrooms?  Then we are the right company for you, so let us take care of the moving cleaning so that you can spend the time organizing the rest of the move instead!',
    homeCleaning: "Home Cleaning",
    homeCleaningContent: "Are you tired of cleaning at home or do you feel that the requirements are too high to have a shiny everyday life with shiny floors, a radiant clean stove when you come home after a long working day, leave it to us as professionals at home cleaning.  We take care of the cleaning in your home so that the tablecloth enjoys a clean home.  You can choose to subscribe to home cleaning or tailor it to your needs and we also have special offers for you who hire us regularly.",
    officeCleaning: "Office Cleaning",
    officeCleaningContent: "A clean and fresh office environment is fundamental in creating well-being in the workplace for both staff and customers.  Let us at Sudtek make sure that your company is always spotless. We adapt office cleaning to your company's wishes - and of course to the times that suit you best.  We are hired by small, medium and large companies, every day of the week, day or evening, weekdays and weekends.  Sudtek works according to these principles: • environmentally conscious • accuracy • quality • security.",
    companyCleaning: "Company Cleaning",
    windowCleaner: "window cleaner",
    bigCleaning: "big cleaning",
    floorCare: "floor care",
    recyclingProducts: "recycling products",
    cleaningIntroP1: "Sudtek is a cleaning company in Linköping with staff with extensive experience in cleaning, " +
      "housework and customer satisfaction. We guarantee security, integrity and honesty",
    cleaningIntroP2: "Our focus is to create a good relationship with our customers. We strive to maintain a high quality of " +
      "the services we provide. Most of our customer base is based on references from existing customers.",
    EnvironmentalPolicy: "Environmental policy",
    EnvironmentalPolicyIntro: "follows an environmental management system to ensure that current legislation and environmental requirements are monitored and complied with by us. Clear routines make us an environmentally friendly and environmentally conscious company. We follow developments and work for a sustainable and long-term society.",
    ExamplesThat: "Some examples are that",
    ExampleOne: "We are consciously thinking of limiting the use of chemicals",
    ExampleTwo: "We choose suppliers who offer products that meet the environmental criteria",
    ExampleThree: "We have product descriptions of the products we use in the business. We follow the recommendations that come with the products to reduce the impact on the environment",
    ExampleFour: "We keep up to date with new alternatives that can help us gradually become more and more environmentally friendly in step with the development of new products",

  },
  sv: {
    home: 'Hem',
    book: 'boka nu',
    aboutUsContent: 'SUDTEK är ett städföretag som har sin bas i Linköping men tar på oss ' +
      'förbokade städuppdrag i nästan hela Sverige. Sudtek erbjuder ' +
      '<span class="highlight"> högkvalitativa tjänster </span> inom hemstäd, ' +
      'flyttstäd, företagssstäd, byggstäd och mycket mer inom området. Vi ' +
      'utför även arbeten som fönsterputs, golvvård och det som krävs för att ' +
      'få ditt hem eller arbetsplats skinande rent och fräscht. Vår ' +
      'supertrevliga personal har lång erfarenhet och kunskap inom allt som ' +
      'har med städning att göra. Vi strävar efter att upprätthålla en hög ' +
      'kvalité på de tjänster som vi tillhandahåller. Vår personal är ' +
      'serviceinriktade och <span class="highlight">kvalitetsmedvetna </span> med fokus på ' +
      '<span class="highlight"> 100 % kundnöjdhet. </span>' +
      'Skulle en miss uppstå så åtgärdar vi det direkt! Vår kundbas baseras ' +
      'på referenser från befintliga kunder. Sudtek prioriterar trygghet för ' +
      'våra kunder och anställda. Vi har ' +
      '<span class="highlight"> heltäckande ansvarsförsäkring </span>' +
      'som täcker eventuella skador i samband med städningen. ',
    weConfirmBooking: "Vi kommer att kontakta dig för att bekräfta din bokning ",
    sendUsAMessage: 'skicka oss ett meddelande',
    thankYouForContacting: 'Tack för att du kontaktar oss',
    contactInfo: 'Kontaktinformation',
    serviceType: 'servicetyp',
    spaceMeters: 'antal m²',
    fullName: 'fullständiga namn',
    email: 'e-post',
    address: 'adress',
    phoneNumber: 'telefonnummer',
    send: 'skicka',
    cancel: 'avbryt',
    thankYou: "tack",
    aboutUs: "om oss",
    contactUs: "kontakta oss",
    cleaningServices: "Städtjänster",
    movingCleaning: "Flyttstäd",
    ourServices: "Våra Tjänster",
    readMore: "Läs mer",
    showLess: "Visa mindre",
    movingCleaningContent: "Är det dags att flytta? <br/> " +
      "Tycker du att det är både energi – och tidskrävande att behöva åka " +
      "tillbaka och börja rengöra spis och fläkt, putsa fönster och skrubba " +
      "badrum? Då är vi rätt företag för dig så låt oss ta hand " +
      "om flyttstädningen så att du kan ägna tiden åt att organisera resten av " +
      "flytten istället!",
    homeCleaning: "Hemstäd",
    homeCleaningContent: "Är du trött på<span class='ml-1'>att</span> städa hemma eller känner du att kraven är för hög på " +
      "att ha en glänsande vardag med blänkande golv, en strålande ren spis " +
      "när man kommer hem efter en lång arbetsdag så överlåt det till oss som " +
      "är proffs på hemstädning. Vi tar hand om städningen i ditt hem så att du " +
      "kan njuta av ett rent hem. Du kan välja på att abonnera på hemstädning " +
      "eller skräddarsy det efter dina behov och vi har även specialerbjudanden " +
      "för dig som anlitar oss regelbundet. ",
    officeCleaning: "Kontorstädning",
    officeCleaningContent: "En ren och fräsch kontorsmiljö är grundläggande i att skapa trivsel " +
      "på arbetsplatsen för både personal och kunder. Låt oss på Sudtek se till " +
      "att det är alltid skinande rent på ert företag. Vi anpassar kontorsstädning " +
      "efter ert företags önskemål – och naturligtvis till de tider som passar er " +
      "bäst. Vi anlitas av små, mellanstora och stora företag, alla dagar i veckan, " +
      "dag- eller kvällstid, vardag som helg. " +
      "Sudtek arbetar efter dessa principer: • miljömedvetet •noggrannhet • " +
      "kvalité • trygghet.",
    companyCleaning: "företagsstäd",
    windowCleaner: "fönsterputs",
    bigCleaning: "storstäd",
    floorCare: "golvvård",
    recyclingProducts: "återvinningsprodukter",
    cleaningIntroP1: "Sudtek är ett städföretag i Linköping med personal med stor erfarenhet av städning, " +
      "hushållsarbete och kundnöjdhet. Vi garanterar trygghet, integritet och ärlighet. ",
    cleaningIntroP2: "Vårt fokus är att skapa ett bra förhållande med våra kunder. Vi strävar efter att upprätthålla en " +
      "hög kvalitet på de tjänster vi tillhandahåller. De flesta av våra kundbas baseras på referenser från " +
      "befintliga kunder.",
    EnvironmentalPolicy: "Miljöpolicy",
    EnvironmentalPolicyIntro: "Sudtek  följer ett miljöledningssystem för att garantera att rådande lagstiftning och miljökrav bevakas och efterlevs av oss. Tydliga rutiner gör att vi är ett miljövänligt och miljömedvetet företag. Vi följer med i utvecklingen och verkar för ett hållbart och långsiktigt samhälle.",
    ExamplesThat: "Några exempel är att",
    ExampleOne: "Vi funderar medvetet på att begränsa användningen av kemikalier",
    ExampleTwo: "Vi väljer leverantörer som erbjuder produkter som uppfyller miljökriterierna",
    ExampleThree: "Vi har produktbeskrivningar av de produkter vi använder i verksamheten. Vi följer rekommendationerna som följer med produkterna för att minska miljöpåverkan",
    ExampleFour: "Vi håller oss uppdaterade med nya alternativ som kan hjälpa oss att gradvis bli mer och mer miljövänliga i takt med utvecklingen av nya produkter",
  }
};

const i18n = new VueI18n({
  locale: "sv", // set locale
  fallbackLocale: "en", // set fallback locale
  messages // set locale messages
});

export default i18n;